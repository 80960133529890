import React from 'react'
import {Container, Row, Col} from 'reactstrap'
import {useStaticQuery, graphql} from 'gatsby'
import Heading from '../../../components/shared/heading'
import {CassieTitleWrap} from './cassie-title-area.stc'

const CassieTitleArea = ({titleCSS, taglineCSS}) => {
    const CassieTitleData = useStaticQuery(graphql `
        query CassieTitleQuery {
            aboutcharacterJson(id: {eq: "cassie-page-header"}) {
                title
                tagline
            }
        }
    `);
    const {title, tagline} = CassieTitleData.aboutcharacterJson
    console.log("here is the title",title)
    return (
        <CassieTitleWrap>
            <Container>
                <Row>
                    <Col lg={{size: 10, offset: 1}} xl={{size: 7, offset: 2}}>
                        {title && <Heading {...titleCSS}>{title}</Heading>}
                        {tagline && <Heading {...taglineCSS}>{tagline}</Heading>}
                    </Col>
                </Row>
            </Container>
        </CassieTitleWrap>
    )
}

CassieTitleArea.defaultProps = {
    titleCSS: {
        as: "h5",
        fontSize: [1],
        textTransform: "capitalize",
        mb: "17px"
    },
    taglineCSS: {
        color: "secondaryColor",
        fontSize: [4, 5],
        fontWeight: "bold",
        lineHeight: [1.4, 1.375]
    }
}
  
export default CassieTitleArea
