import React from "react"
import SEO from '../components/seo'
import Layout from "../containers/layout/layout"
import Header from '../containers/layout/header'
import Footer from '../containers/layout/footer'
import CassieTitleArea from '../containers/cassie/cassie-title-area'
import CassieBannerArea from '../containers/cassie/cassie-banner-area'
import CassieContentArea from '../containers/cassie/cassie-content-area'
import ArtworkArea from '../containers/global/artwork'
 
const CassiePage = () => (
  <Layout>
    <SEO 
        title="Cassie and the Spectral Shade" 
        description="Cassie Page description"
        keywords={["character", "design", "portfolio"]}
    />
    <Header/>
    <div className="main-content">
        <CassieTitleArea/>
        <CassieBannerArea/>
        
        <CassieContentArea/>
        <ArtworkArea/>   
    </div>
    <Footer/>
  </Layout>
)

export default CassiePage
